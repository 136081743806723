/**
 * Skrevet av Mikael
 */

body {
    font-family: Cambria;
    padding-top: 60px;
    background-color: #e6e2d3;
}

.bakgrunn {
  /*background-image: url("gobgob.png");*/
  background-position: right;
  height: 50%;
  background-repeat: no-repeat;
}
.logo {
  color: white;
  margin-right: 30px;
}
nav {
  background-color: #3e4444;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  
}
nav li a {
  color: #c1946a;
  text-decoration: none;
  padding: 34px 30px;
  
  
  
}
.navKnapp:hover {
    background-color: #c1946a;
    color: black;
    
  }
nav * {
  display: inline;
}

.navKnapp {
  right: 0;
  transition: 0.3s;
}

form {
  max-width: 20%;
  min-height: 300px;
  min-width: 200px;
  margin: auto;
  width: 50%;
  position: absolute;
  top: 70%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #3e4444;
  padding: 1.5%;
  border-radius: 20px;
  height: 35%;
  position: relative;
  overflow: auto;
}

.signInForm {
  min-height: 400px;
}

label {
  height: 22px;
  margin-bottom: 5px;
  margin-top: 30px;
  display: block;
  color: #c1946a;
}

input {
  display: block;

  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #333;
}

.loginBtn {
  padding: 10px 17px;
  background-color: #c1946a;
  color: #333;
  border: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 12%;
  border-radius: 3px;
  transition: 0.3s;
}

.loginBtn:hover {
  cursor: pointer;
  background-color: #e6e2d3;
  color: #333;
}
.input-feedback {
  color: #c1946a;
  font-size: small;
  margin-bottom: -16px;
}
.formTitle {
  font-size: xx-large;
  text-align: center;
  margin-top: 0px;
  
  
}
footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #3e4444;
  color: #c1946a;
  z-index: -1;
}